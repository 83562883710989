import React, { Fragment } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Container from '@material-ui/core/Container';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { crud } from '../services/crud';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import TablePagination from '@material-ui/core/TablePagination';
import { format } from 'date-fns';
import { withRouter } from 'react-router-dom';
import { red, purple, green, blue } from '@material-ui/core/colors';
import {
    Button, Dialog, DialogContent, DialogActions, DialogContentText,
    Typography, CircularProgress, FormControlLabel, Switch, TextField,
    Snackbar
} from '@material-ui/core';
import { Grid } from '@material-ui/core';
import Profile from '../layouts/authenticated/components/sidebar/Profile';
import http from '../services/http';
import config from '../config/config';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    container: {
        padding: '100px 1em 1em 1em',
        minHeight: '100vh',
        width: '100%'
    },
    indicator: {
        listStyleType: "square",
        '& li': {
            content: "•",
            fontWeight: "bold",
            // display: "inline-block",
            // width: "1em",
            marginLeft: "-1em"
        }
    }
});

const AdminUsers = (props) => {
    const [rows, setRows] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [id, setId] = React.useState(false);
    const [status, setStatus] = React.useState('all');
    const [refresh, setRefresh] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [isLoading, setIsLoading] = React.useState(true);
    const [filter, setFilter] = React.useState('');
    const classes = useStyles();
    const orders = crud('settings/users');
    //   const filter = props.match.params.filter;

    const getOrders = async () => {
        setIsLoading(true);
        setRows(false);
        let params = {};
        if(filter) {
            params = {
                ...params,
                'keyword': filter
            }
        }
        let res = await orders.find(params);
        if (res) {
            if (res.data) {
                if (res.data.length > 0) {
                    setRows(res.data);
                } else {
                    setRows([]);
                }
            }
        }
        setIsLoading(false);
    }

    const handleOnChange = (event) => {
        setFilter(event.target.value);
    }

    const handleChangePage = (event, page) => {
        setPage(page);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleClose = (e, reason) => {
        if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            return;
        }
        if(refresh) {
            getOrders();
            setRefresh(false);
        }
        setOpen(false);
    };

    const handleOpen = (i) => {
        setId(i);
        setOpen(true);
    }

    React.useEffect(() => {
        const getData = setTimeout(() => {
            getOrders();
          }, 1500);
          return () => clearTimeout(getData);
    }, [filter]);

    return (
        <div className={classes.container}>
            <Container component={Paper} elevation={3} style={{ padding: '1.5em' }}>
                <div style={{margin: '10px 0'}}>
                    <TextField variant='outlined' value={filter} onChange={handleOnChange} placeholder='Search'/>
                </div>
                {
                    rows &&
                    <Table className={classes.table} size="small" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Phone Number</StyledTableCell>
                                <StyledTableCell align="center">Name</StyledTableCell>
                                <StyledTableCell align="center">Flagged</StyledTableCell>
                                <StyledTableCell align="center">Blocked</StyledTableCell>
                                <StyledTableCell align="center">Updated At</StyledTableCell>
                                <StyledTableCell align="center">Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((row, idx) => (
                                <StyledTableRow hover key={idx}>
                                    <StyledTableCell>{`0${row.username}`}</StyledTableCell>
                                    <StyledTableCell align="center">{row.meta.firstName ? row.meta.firstName : 'User'} {row.meta.lastName ? row.meta.lastName : 'User'}</StyledTableCell>
                                    <StyledTableCell align="center">{row.flagged ? 'yes' : 'no'}</StyledTableCell>
                                    <StyledTableCell align="center">{row.orderBlocked ? 'yes' : 'no'}</StyledTableCell>
                                    <StyledTableCell align="center">{format(new Date(row.updatedAt), "MMM. dd, yyyy hh:mm a")}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <IconButton onClick={() => { handleOpen(row._id) }}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                }
                {
                    (rows.length == 0 && !isLoading) &&
                    <div style={{ textAlign: 'center', width: '100%', padding: '30px 0' }}>
                        <Typography variant='h4'>There is nothing to show.</Typography>
                        <Typography variant='subtitle2'>No results found.</Typography>
                    </div>
                }
                {
                    isLoading &&
                    <div style={{ textAlign: 'center', width: '100%', padding: '30px 0' }}>
                        <CircularProgress size={35} />
                        <Typography style={{ paddingTop: '5px' }} variant='subtitle2'>Loading...</Typography>
                    </div>
                }
                <TablePagination
                    rowsPerPageOptions={[20]}
                    component="div"
                    count={rows.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Container>

            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="lg">
                <DialogContent>
                    <AdminUser id={id} refresh={(val) => {setRefresh(val);}}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};

const AdminUser = (props) => {
    const users = crud('settings/users');
    const [user, setUser] = React.useState(false);
    const [haveEdits, setHaveEdits] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [msg, setMsg] = React.useState('');

    const getUser = async () => {
        let res = await users.findOne(props.id);
        if (res) {
            setUser({ ...res.data });
        }
    };

    const handleSwitchChange = (event) => {
        setHaveEdits(true);
        setUser({
            ...user,
            [event.target.name]: event.target.checked
        });
    }

    const handleTextChange = (event) => {
        setHaveEdits(true);
        setUser({
            ...user,
            notes: event.target.value
        });
    }

    const save = async () => {
        await http({
            method: 'put',
            url: `${config.app.server.url}/settings/users/${user._id}`,
            data: {
                flagged: user.flagged,
                orderBlocked: user.orderBlocked,
                notes: user.notes
            },
        })
            .then(res => {
                getUser();
                setMsg('User updated.');
                setSnackbarOpen(true);
                setDialogOpen(false);
                setHaveEdits(false);
                props.refresh(true);
            })
            .catch(err => {
                setMsg('Unable to update user.');
                setSnackbarOpen(true);
                setDialogOpen(false);
            });
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    React.useEffect(() => {
        getUser()
    }, []);
    return <div style={{ width: '100%'}}>
        <Grid container>
            <Grid xs={12} sm={12} md={6} item>
                {
                    user &&
                    <Fragment>
                        <Profile user={{ name: `${user.meta.firstName} ${user.meta.lastName}`, phone: `0${user.meta.phone}` }} />
                        <br />
                        <Grid container>
                            <Grid xs={6} item>
                                <div style={{ textAlign: 'center' }}>
                                    <Typography variant='h3' style={{ color: '#27AE60' }}>
                                        {user.completed}
                                    </Typography>
                                    <Typography variant='subtitle1'>
                                        Completed
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid xs={6} item>
                                <div style={{ textAlign: 'center' }}>
                                    <Typography variant='h3' style={{ color: '#E74C3C' }}>
                                        {user.cancelled}
                                    </Typography>
                                    <Typography variant='subtitle1'>
                                        Cancelled
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Fragment>

                }
            </Grid>
            <Grid xs={12} sm={12} md={6} item>
                {
                    user &&
                    <Fragment>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={user.flagged}
                                    onChange={handleSwitchChange}
                                    name="flagged"
                                    color="primary"
                                />
                            }
                            label="Flagged"
                        />
                        <br />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={user.orderBlocked}
                                    onChange={handleSwitchChange}
                                    name="orderBlocked"
                                    color="primary"
                                />
                            }
                            label="Blocked"
                        />
                        <TextField variant='filled' style={{ marginTop: '10px' }} onChange={handleTextChange} value={user.notes} multiline minRows={5} label="Notes" placeholder='Write Something...' fullWidth />
                        {haveEdits && <Button onClick={() => { setDialogOpen(true); }} style={{ marginTop: '10px' }} variant='contained' color="primary" fullWidth>Save</Button>}
                    </Fragment>
                }
            </Grid>
        </Grid>
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to continue?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                    No
                </Button>
                <Button color="primary" onClick={save} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{msg}</span>}
            action={[
                <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={handleSnackbarClose}
                >
                    <CloseIcon />
                </IconButton>,
            ]}
        />
        {/* <pre>{JSON.stringify(user, null, 4)}</pre> */}
    </div>;
}

export default withRouter(AdminUsers);