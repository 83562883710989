import React, { Fragment, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { crud } from '../services/crud';
import { withRouter } from 'react-router-dom';
import Profile from '../layouts/authenticated/components/sidebar/Profile';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { format } from 'date-fns';
import Viewer from 'react-viewer';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AuthContext } from '../auth/AuthContext';
import config from '../config/config';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import airportList from '../assets/airports.json';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import HourglassEmptySharpIcon from '@material-ui/icons/HourglassEmptySharp';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import BlockSharpIcon from '@material-ui/icons/BlockSharp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { red, purple, green, blue } from '@material-ui/core/colors';
import HourglassFullSharpIcon from '@material-ui/icons/HourglassFullSharp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SelectAllIcon from '@material-ui/icons/SelectAll';

const useStyles = makeStyles(theme => (
  {
    table: {
      minWidth: 700,
    },
    container: {
      padding: '100px 1em 1em 1em',
      minHeight: '100vh',
      width: '100%',
      zIndex: '1200'
    },
    button: {
      margin: '0.5em 0',
      background: '#e0e0e0',
      padding: "0.5em 1.5em"
    },
    close: {
      padding: theme.spacing(0.5),
    },
    formControl: {
      minWidth: '220px',
      margin: '1em 0'
    },
    selectMenu: {
      '& .MuiSelect-outlined': {
        display: 'flex',
        alignItems: 'center'
      },
    }
  }
));

const AdminOrder = (props) => {
  const { user } = React.useContext(AuthContext);
  const [order, setOrder] = React.useState({});
  const [isEdit, setIsEdit] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogDeclineOpen, setDialogDeclineOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isEdited, setIsEdited] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const classes = useStyles();
  const orders = crud('orders');

  const textRef = useRef(null);

  const plans = [
    { label: "1 Month", price: "500", type: 1 },
    { label: "6 Months<br/>+ 1 Month Free", price: "3000", type: 2 },
    { label: "10 Months <br/> + 2 Months Free", price: "5000", type: 3 }
  ];

  const orderId = props.match.params.id;
  const [reason, setReason] = React.useState('');

  const getOrder = async () => {
    let res = await orders.findOne(orderId);
    if (res) {
      setOrder({ ...res.data });
    }
  }

  const update = () => {
    setDialogOpen(true);
  }

  const decline = () => {
    setDialogDeclineOpen(true);
  }

  const updateOrder = async () => {
    handleDialogClose();
    setIsLoading(true);
    let _order = {
      ...order,
      updatedBy: user.name
    }
    let res = await orders.save(_order);
    if (res) {
      if (res.data) {
        setMsg('Order Updated.');
        setOpen(true);
        getOrder();
      }
    }

    setIsEdited(false);
    setIsLoading(false);
  }

  const declineOrder = async () => {
    handleDialogClose();
    setIsLoading(true);
    let res = await orders.erase(order.id);
    if (res) {
      if (res.data) {
        props.history.push('/orders');
      }
    }

    setIsLoading(false);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogDeclineOpen(false);
  };

  const handleChange = (e) => {
    setIsEdited(true);
    setOrder({
      ...order,
      meta: {
        ...order.meta,
        reason: e.target.value
      }
    });
  }

  const handleSelectChange = (e, field) => {
    setIsEdited(true);
    const val = e.target.value;

    if (typeof val == 'number') {
      const idx = plans.findIndex(x => x.type === val);
      setOrder({
        ...order,
        [field]: val,
        [`${field}_`]: plans[idx]
      });
    } else {
      const label = val.charAt(0).toUpperCase() + val.slice(1);
      setOrder({
        ...order,
        [field]: val,
        [`${field}_`]: {
          label: label,
          value: val
        }
      });
    }
  }

  const getType = t => {
    switch (t) {
      case 'cebu': return 'With in Cebu'; break;
      case 'airport': return 'Airport to Airport'; break;
      case 'pickup': return 'Pickup'; break;
    }
  }

  const getPayment = p => {
    switch (p) {
      case 'xendit': return 'Xendit'; break;
      case 'cod': return 'CASH ON DELIVERY'; break;
      case 'cc': return 'Credit Card'; break;
      case 'bank': return 'Bank Deposit/G-Cash'; break;
      case 'maya': return 'Paymaya'; break;
    }
  }

  const getAirport = a => {
    const _airport = airportList.filter((x) => {
      return x.iata_code == a
    });
    if (_airport.length > 0) {
      return `${_airport[0].iata_code} | ${_airport[0].name} - ${_airport[0].city}`;
    }
    return ''
  }

  const getStyle = t => {
    switch (t) {
      case 'cod': return purple[400];
      case 'bank': return red[400];
      case 'cc': return blue[400];
      case 'maya': return green[200];
    }
  }

  const copy = () => {
    navigator.clipboard.writeText(order.confirmation);
    setMsg('Text copied to clipboard.');
    setOpen(true);
  }

  const selectAll = () => {
    textRef.current.select();
  }

  React.useEffect(() => {
    getOrder();
  }, []);
  return (
    <div className={classes.container}>
      <Container maxWidth="md" component={Paper} elevation={3} style={{ padding: '1.5em' }}>
        <Button
          onClick={() => { props.history.go(-1) }}
          startIcon={<ArrowBackIcon />}
        >
          Back to Orders
        </Button>
        <Grid container>
          <Grid xs={12} md={4} item>
            {Object.keys(order).length > 0 &&
              <React.Fragment>
                <div style={{ padding: '15px 0' }}></div>
                <Profile user={{ name: `${order.user.meta.firstName} ${order.user.meta.lastName}`, phone: `0${order.user.meta.phone}` }} />
                <Divider style={{ margin: '15px 0' }} />
                <List>
                  <ListItem>
                    <ListItemText style={{ boxShadow: `8px 0px 0px 0px ${getStyle(order.meta.payment.method)}` }} primary="Payment" secondary={getPayment(order.meta.payment.method)} />
                  </ListItem>
                  {
                    (order.meta.payment.method == 'xendit' || order.status == 'downpayment') &&
                    <Fragment>
                      {
                        order.status == 'confirmed' &&
                        <Fragment>
                          <ListItem>
                            <ListItemText primary="Payment Method" secondary={order.payment.meta.payment_method} />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Payment Channel" secondary={order.payment.meta.payment_channel} />
                          </ListItem>
                        </Fragment>
                      }

                      {
                        (order.status == 'pending' || (order.status == 'downpayment' && order.payment)) &&
                        <Fragment>
                          <ListItem>
                            <ListItemText primary="Expires On" secondary={format(new Date(order.payment.meta.expiry_date), "MMM. dd, yyyy hh:mm a")} />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Redirect Url" secondary={order.payment.meta.invoice_url} />
                          </ListItem>
                        </Fragment>
                      }
                    </Fragment>
                  }
                  <ListItem>
                    <ListItemText primary="Type" secondary={getType(order.meta.type)} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Date & Time" secondary={`${format(new Date(order.meta.date), 'MMM. dd, yyyy')} ${format(new Date(order.meta.time), 'hh:mm a')}`} />
                  </ListItem>
                  {order.meta.type == 'cebu' &&
                    <ListItem>
                      <ListItemText primary="Address" secondary={`${order.meta.unit ? `${order.meta.unit}, ` : ''}${order.meta.street}, ${order.meta.barangay}, ${order.meta.city}`} />
                    </ListItem>
                  }
                  {order.meta.type == 'airport' &&
                    <ListItem>
                      <ListItemText primary="Airport" secondary={getAirport(order.meta.airport)} />
                    </ListItem>
                  }
                  <ListItem>
                    <ListItemText primary="Contact Person" secondary={`${order.meta.firstName} ${order.meta.lastName} | 0${order.meta.phone}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Updated At" secondary={format(new Date(order.updatedAt), "MMM. dd, yyyy hh:mm a")} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Created At" secondary={format(new Date(order.createdAt), "MMM. dd, yyyy hh:mm a")} />
                  </ListItem>
                </List>
              </React.Fragment>
            }
          </Grid>
          <Grid xs={12} md={8} item>
            {Object.keys(order).length > 0 &&
              <React.Fragment>

                {order.items &&
                  isEdit ?
                  null
                  // <div>
                  //   <div style={{background: '#f8f8f8'}}>
                  //     <JsonEditor
                  //       data={order}
                  //       onChange={data => {

                  //       }}
                  //       // key={isEdit}
                  //     />
                  //   </div>
                  //    <Button variant="outlined" onClick={e=>{setIsEdit(false)}}>Cancel Edit</Button>
                  // </div>
                  :
                  <div style={{ padding: '10px 50px' }}>
                    <Typography style={{ marginTop: '10px', fontWeight: '700' }} color="primary" variant="subtitle2">Order Summary</Typography>
                    <Typography className={classes.labels} variant="caption">
                      ITEMS
                    </Typography>
                    {
                      order.items.map((item, idx) => (
                        <Fragment key={idx}>
                          <Grid container style={{ marginTop: '10px' }}>
                            <Grid item xs={4}>
                              <Typography><b>{item.type == "whole" ? "Whole Lechon" : "Lechon Belly"}</b></Typography>
                            </Grid>
                            <Grid item xs={4}>
                              {/* <Typography>X{item.qty}</Typography> */}
                            </Grid>
                            <Grid item xs={4}>

                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={4}>
                              <Typography style={{ paddingLeft: '10px' }}>{item.size.charAt(0).toUpperCase() + item.size.substring(1)} {item.type == 'belly' && `(${item.size.charAt(1)}KG)`} - {item.flavor == 'spicy' ? "Spicy" : "Original"}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography>X{item.qty}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>{(item.qty * item.price).toLocaleString()}</Typography>
                            </Grid>
                          </Grid>
                        </Fragment>
                      ))
                    }
                    <Divider variant="middle" style={{ backgroundColor: "#d1d1d1", marginTop: '25px', marginBottom: '25px' }} />
                    <Grid container style={{ marginTop: '10px' }}>
                      <Grid item xs={6}>
                        <Typography><b>Subtotal</b></Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseInt(order.meta.subTotal).toLocaleString()}</Typography>
                      </Grid>
                    </Grid>
                    {order.meta.fees &&
                      <Fragment>
                        {order.meta.fees.delivery &&
                          <Grid container style={{ marginTop: '10px' }}>
                            <Grid item xs={6}>
                              <Typography><b>Delivery</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>{order.meta.fees.delivery.toLocaleString()}</Typography>
                            </Grid>
                          </Grid>
                        }
                        {order.meta.fees.freight &&
                          <Grid container style={{ marginTop: '10px' }}>
                            <Grid item xs={6}>
                              <Typography><b>Freight & Packing</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>{order.meta.fees.freight.toLocaleString()}</Typography>
                            </Grid>
                          </Grid>
                        }
                      </Fragment>
                    }
                    <Divider variant="middle" style={{ backgroundColor: "#d1d1d1", marginTop: '25px', marginBottom: '25px' }} />
                    <Grid container style={{ marginTop: '10px' }}>
                      <Grid item xs={9}>
                        <Typography variant="h4"><b>Total:</b></Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="h4" style={{ textAlign: 'right', fontWeight: 'bold', marginTop: '10px' }}>&#8369;{parseInt(order.total).toLocaleString()}.00</Typography>
                      </Grid>
                    </Grid>


                    {/* <Button variant="outlined" onClick={e=>{setIsEdit(true)}}>Edit Items</Button> */}
                  </div>
                }

                {order.meta.payment.method == "bank" &&
                  <Fragment>
                    {
                      (order.proof.date || order.proof.reference || order.proof.photo) ?

                        <Fragment>
                          <List>
                            <ListItem>
                              {order.proof.date &&
                                <ListItemText primary="Date of Deposit" secondary={format(new Date(order.proof.date), "MMM. dd, yyyy")} />
                              }
                            </ListItem>
                            {order.proof.reference &&
                              <ListItem>
                                <ListItemText primary="Reference No." secondary={order.proof.reference} />
                              </ListItem>
                            }
                            {order.proof.photo &&
                              <React.Fragment>
                                <ListItem>
                                  <ListItemText primary="Proof of Payment (Photo)" />
                                </ListItem>
                                <img src={config.app.server.url + order.proof.photo} onClick={() => { setVisible(true); }} style={{ width: '400px', cursor: 'pointer' }} />
                                <Viewer
                                  visible={visible}
                                  onClose={() => { setVisible(false); }}
                                  rotatable={false}
                                  scalable={false}
                                  zIndex={9999}
                                  images={[{ src: config.app.server.url + order.proof.photo, alt: 'proof' }]}
                                />
                              </React.Fragment>
                            }

                          </List>
                        </Fragment>
                        :
                        <h3>No update yet.</h3>
                    }
                  </Fragment>


                }
                {(order.status == 'downpayment' && order.payment) &&
                  <div style={{ width: '100%', padding: '15px' }}>
                    <hr />
                    <br />
                    <Typography variant="h5">Downpayment (Xendit)</Typography>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography>Ref. ID</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{order.payment.meta.external_id}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography>Amount</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>&#8369; {order.payment.meta.amount.toFixed(2)}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography>Updated</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{format(new Date(order.payment.meta.updated), "MMM. dd, yyyy hh:mm a")}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography>Status</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{order.payment.meta.status}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                }

                <List>
                  <ListItem>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Status</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        value={order.status}
                        onChange={e => { handleSelectChange(e, 'status') }}
                        label="Status"
                        className={classes.selectMenu}
                        fullWidth
                      >
                        <MenuItem value="completed">
                          <DoneAllSharpIcon style={{ marginRight: '8px' }} />Completed
                        </MenuItem>
                        <Divider style={{ margin: '8px' }} />
                        <MenuItem value="confirmed"><CheckCircleSharpIcon style={{ marginRight: '8px' }} />Confirm</MenuItem>
                        <MenuItem value="downpayment"><HourglassFullSharpIcon style={{ marginRight: '8px' }} />Pending (Downpayment)</MenuItem>
                        <MenuItem value="pending"><HourglassEmptySharpIcon style={{ marginRight: '8px' }} />Pending</MenuItem>
                        {((order.proof.date || order.proof.reference || order.proof.photo) && order.meta.payment.method == "bank") &&
                          <MenuItem value="rejected"><BlockSharpIcon style={{ marginRight: '8px' }} />Reject (Proof of Payment)</MenuItem>
                        }
                        <MenuItem value="cancelled"><CancelSharpIcon style={{ marginRight: '8px' }} />Cancel</MenuItem>
                      </Select>
                    </FormControl>
                  </ListItem>

                  {(order.status == 'rejected' || order.status == 'cancelled') &&
                    <ListItem>
                      <TextField
                        label="Reason"
                        autoFocus
                        margin="dense"
                        id="name"
                        placeholder="Write Something .."
                        fullWidth
                        multiline
                        rows="4"
                        onChange={handleChange}
                        value={order.meta.reason}
                        variant="filled"
                      />
                    </ListItem>
                  }
                </List>
                {isLoading ?
                  <CircularProgress />
                  :
                  <Fragment>
                    {
                      isEdited &&
                      <Fragment>
                        <Button style={{ marginRight: '5px' }} className={classes.button} onClick={update}>Save</Button>
                      </Fragment>
                    }
                    {/* <Button color="secondary" className={classes.button} onClick={decline}>Delete</Button> */}
                  </Fragment>
                }
                {
                  order.confirmation &&
                  <Fragment>
                    <br />
                    <br />
                    <hr />
                    <Typography style={{ marginTop: '10px', fontWeight: '700' }} color="primary" variant="subtitle1">SMS Confirmation</Typography>
                    <Button startIcon={<FileCopyIcon />} style={{ marginRight: '5px' }} className={classes.button} onClick={copy} disabled>Copy</Button>
                    <Button startIcon={<SelectAllIcon />} style={{ marginRight: '5px' }} className={classes.button} onClick={selectAll}>Select All</Button>

                    <TextField
                      // label="SMS Confirmation"
                      inputRef={textRef}
                      inputProps={{
                        readonly: "readonly"
                      }}
                      autoFocus
                      margin="dense"
                      id="name"
                      placeholder="Write Something .."
                      fullWidth
                      multiline
                      value={order.confirmation}
                      variant="filled"
                      // disabled
                    />
                  </Fragment>
                }
              </React.Fragment>
            }
          </Grid>
        </Grid>
      </Container>

      {/* <pre>{JSON.stringify( order, null, 4)}</pre> */}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{msg}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No
          </Button>
          <Button onClick={updateOrder} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogDeclineOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            No
          </Button>
          <Button onClick={declineOrder} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
};

export default withRouter(AdminOrder);