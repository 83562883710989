import React from "react";
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Logout from '../pages/Logout';
import {AuthContext} from '../auth/AuthContext';
import AdminOrders from '../pages/AdminOrders';
import AdminLayout from '../layouts/admin/';
import AdminOrder from '../pages/AdminOrder';
import AdminLogin from '../pages/LoginAdmin';
import AdminSettings from '../pages/AdminSettings';
import AdminSetting from '../pages/AdminSetting';
import AdminUsers from '../pages/AdminUsers';

export default function Routes() {
const auth = React.useContext(AuthContext);

const checkAuth= () => {
  return Object.keys(auth.user).length > 0;
}

const isAuthenticated = checkAuth();

const _routes = [
  {
    path: "/orders/view/:id",
    comp: <AdminOrder />,
    isAuth: true
  },
  {
    path: "/orders/:filter",
    comp: <AdminOrders />,
    isAuth: true
  },
  {
    path: "/users",
    comp: <AdminUsers />,
    isAuth: true
  },
  {
    path: "/settings",
    comp: <AdminSettings />,
    isAuth: true
  },
  {
    path: "/settings/:id",
    comp: <AdminSetting />,
    isAuth: true
  },
  {
    path: "/reports",
    comp: <AdminSettings />,
    isAuth: true
  },
  {
    path: "/users",
    comp: <AdminSettings />,
    isAuth: true
  },
  {
    path: "/logout",
    comp: <Logout />,
    isAuth: true
  }
];

const paths = _routes.map((x, idx) => {
   return (isAuthenticated ?
    <Route exact path={x.path} key={idx}>
          <AdminLayout>
            {x.comp}
          </AdminLayout> 
    </Route> :
    <Redirect exact from={x.path} to="/login" key={idx} /> 
   )
});

    return (
        <Switch>
            <Redirect exact from="/" to="orders/all" key="orders" />
            <Redirect exact from="/orders" to="orders/all" key="ordersToAll" />
            {/* <Redirect exact from="/_hidden" to="_hidden/orders" key="hidden-orders-redirect"/> */}
            {paths}
           {/* {isAuthenticated ? 
           <Route exact path="/_hidden/orders/:id" key="hidden-orders">
              <AdminLayout>
                    <AdminOrder/>
                </AdminLayout> 
            </Route> :
            <Redirect exact from="/_hidden/orders/:id" to="login" key="hidden-orders"/>
          }
          {isAuthenticated ? 
          <Route exact path="/_hidden/orders" key="hidden-order">
            <AdminLayout>
                  <AdminOrders/>
              </AdminLayout> 
          </Route> :
            <Redirect exact from="/_hidden/orders" to="login" key="hidden-orders"/>
          }

          {isAuthenticated ? 
            <Route exact path="/_hidden/contacts" key="hidden-contacts">
              <AdminLayout>
                    <AdminContacts/>
                </AdminLayout> 
            </Route> :
              <Redirect exact from="/_hidden/contacts" to="login" key="hidden-contacts"/>
          }

          {isAuthenticated ? 
            <Route exact path="/_hidden/settings" key="hidden-settings">
              <AdminLayout>
                    <AdminSettings/>
                </AdminLayout> 
            </Route> :
              <Redirect exact from="/_hidden/settings" to="login" key="hidden-settings"/>
          }

        */}
          <Route exact path="/login" key="login">
            {/* <AdminLayout> */}
                  <AdminLogin/>
              {/* </AdminLayout>  */}
          </Route>

          <Route>
            {/* <AdminLayout> */}
                 <h2>Not Found</h2>
              {/* </AdminLayout>  */}
          </Route>
        </Switch>
    )
}
