import React, { Fragment } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Container from '@material-ui/core/Container';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { crud } from '../services/crud';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TablePagination from '@material-ui/core/TablePagination';
import { format } from 'date-fns';
import { withRouter } from 'react-router-dom';
import { red, purple, green, blue } from '@material-ui/core/colors';
import { Button, ButtonGroup, Typography, CircularProgress } from '@material-ui/core';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  container: {
    padding: '100px 1em 1em 1em',
    minHeight: '100vh',
    width: '100%'
  },
  indicator: {
    listStyleType: "square",
    '& li': {
      content: "•",
      fontWeight: "bold",
      // display: "inline-block",
      // width: "1em",
      marginLeft: "-1em"
    }
  }
});

const AdminOrders = (props) => {
  const [rows, setRows] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [status, setStatus] = React.useState('all');
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();
  const orders = crud('orders');
  const filter = props.match.params.filter;

  const getOrders = async () => {
    setIsLoading(true);
    setRows(false);
    let params = { _sort: "updatedAt:DESC", _limit: 99999 };
    if (filter !== 'all') {
      params = {
        status: filter,
        _limit: 99999
      }
    }
    let res = await orders.find(params);
    if (res) {
      if (res.data) {
        if (res.data.length > 0) {
          //  const _rows = [];
          //  res.data.forEach(el => {
          //   _rows.push({
          //     id: el.id,
          //     email: el.email,
          //     name: el.user.name,
          //     type: el.type_.label.replace('<br/>', ''),
          //     payment: el.payment.type,
          //     updateAt: el.updated_at,
          //     status: el.status
          //   });
          //  });
          let _rows = res.data.map(r => {
            const d = new Date(r.meta.date);
            const t = new Date(r.meta.time);
            const dd = new Date(d.getFullYear(), d.getMonth(), d.getDate(),
              t.getHours(), t.getMinutes(), t.getSeconds());

            return {
              ...r,
              deliveryDate: dd
            }
          });

          if (filter !== 'all') {
            _rows = _rows.sort((a, b) => (b.deliveryDate - a.deliveryDate));
          }
          setRows(_rows);
        } else {
          setRows([]);
        }
      }
    }
    setIsLoading(false);
  }

  const handleChangePage = (event, page) => {
    setPage(page);
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const getStyle = t => {
    switch (t) {
      case 'cod': return purple[400];
      case 'bank': return red[400];
      case 'cc': return blue[400];
      case 'maya': return green[200];
    }
  }

  const getType = t => {
    switch (t) {
      case 'cebu': return 'With in Cebu'; break;
      case 'airport': return 'Airport to Airport'; break;
      case 'pickup': return 'Pickup'; break;
    }
  }

  const getPayment = p => {
    switch (p) {
      case 'xendit': return 'Xendit'; break;
      case 'cod': return 'Cash on Delivery'; break;
      case 'cc': return 'Credit Card'; break;
      case 'bank': return 'Bank Deposit/G-Cash'; break;
      case 'maya': return 'Paymaya'; break;
    }
  }

  const statusButtonsClicked = (stat) => {
    if (stat == status) {
      return;
    }
    setStatus(stat);
    getOrders(stat);
  }

  const statusButtons = () => {
    const buttons = ['all', 'completed', 'confirmed', 'pending', 'cancelled'];
    return (<ButtonGroup color="primary" aria-label="outlined primary button group">
      {buttons.map(b => (<Button key={b} href={`#/orders/${b}`} variant={b == filter ? 'contained' : null}>{b}</Button>))}
    </ButtonGroup>);
  }

  React.useEffect(() => {
    getOrders();
  }, [props.match.params.filter]);

  return (
    <div className={classes.container}>
      <Container component={Paper} elevation={3} style={{ padding: '1.5em' }}>
        {statusButtons()}
        <table>
          <tbody>
            <tr>
              <td>
                <ul className={classes.indicator}>
                  <li style={{ color: red[400] }}>Bank Deposit/G-Cash</li>
                </ul>
              </td>
              <td>
                <ul className={classes.indicator}>
                  <li style={{ color: purple[400] }}>Cash on Delivery</li>
                </ul>
              </td>
              <td>
                <ul className={classes.indicator}>
                  <li style={{ color: blue[400] }}>Credit Card</li>
                </ul>
              </td>
              <td>
                <ul className={classes.indicator}>
                  <li style={{ color: green[400] }}>Paymaya</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        {
          rows &&
          <Table className={classes.table} size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="center">Type</StyledTableCell>
                <StyledTableCell align="center">Payment</StyledTableCell>
                <StyledTableCell align="center">Delivery</StyledTableCell>
                <StyledTableCell align="center">Updated At</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">View</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((row, idx) => (
                <StyledTableRow hover key={idx} style={row.status == 'pending' ? { boxShadow: `8px 0px 0px 0px ${getStyle(row.meta.payment.method)}` } : {}}>
                  <StyledTableCell >{row.user.meta.firstName} {row.user.meta.lastName}</StyledTableCell>
                  <StyledTableCell align="center">{getType(row.meta.type)}</StyledTableCell>
                  <StyledTableCell align="center">{getPayment(row.meta.payment.method)}</StyledTableCell>
                  <StyledTableCell align="center">{format(new Date(row.deliveryDate), "MMM. dd, yyyy hh:mm a")}</StyledTableCell>
                  <StyledTableCell align="center">{format(new Date(row.updatedAt), "MMM. dd, yyyy hh:mm a")}</StyledTableCell>
                  <StyledTableCell align="center">{row.status}</StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton href={`#/orders/view/${row._id}`}>
                      <VisibilityIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        }
        {
          (rows.length == 0 && !isLoading) &&
          <div style={{ textAlign: 'center', width: '100%', padding: '30px 0' }}>
            <Typography variant='h4'>There is nothing to show.</Typography>
            <Typography variant='subtitle2'>No results found.</Typography>
          </div>
        }
        {
          isLoading &&
          <div style={{ textAlign: 'center', width: '100%', padding: '30px 0' }}>
            <CircularProgress size={35} />
            <Typography style={{ paddingTop: '5px' }} variant='subtitle2'>Loading...</Typography>
          </div>
        }
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={rows.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
    </div>
  )
};

export default withRouter(AdminOrders);