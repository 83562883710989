import React, { Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Sidebar from './components/sidebar';
import grey from '@material-ui/core/colors/grey';
import {withRouter} from 'react-router-dom';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
    }
  },
  appBar: {
      zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1
  },
  title: {
    color: grey[50]
  }
}));

function DefaultLayout(props) {
  const {children, location} = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} style={{boxShadow: 'none'}}>
        <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
           // className={classes.menuButton}
          >
            <MenuIcon className={classes.MenuIcon}/>
          </IconButton>
          
          <Typography style={{color: '#fff'}} variant="h6">Dashboard</Typography>
          <div style={{flexGrow: 1}}></div>
          <Typography style={{color: '#fff'}} variant="h6">LESLIESEXPRESS.PH</Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Sidebar open={mobileOpen} variant="temporary" onClose={handleDrawerToggle} />
      </nav>
      <main className={classes.content}>
            {children}
      </main>
    </div>
  );
}

export default withRouter(DefaultLayout);
