import React from 'react';
import {AuthContext} from '../auth/AuthContext';
import {withRouter} from 'react-router-dom';
import http from '../services/http';

const Logout = props => {
    const auth = React.useContext(AuthContext);
    React.useEffect(() => {
        if(Object.keys(auth.user).length > 0) {
            auth.setUser({});
            delete http.defaults.headers.common['Authorization'];
        }
        props.history.push('/');
    });

    return (
        <span></span>
    )
}

export default withRouter(Logout);