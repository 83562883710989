import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const {user} = props;
  const { className, ...rest } = props;
  const meta = user;

  const classes = useStyles();

  const finit = meta ? meta.name || meta.email : '';

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        component="div"
        src={meta ? meta.photoUrl || `https://ui-avatars.com/api/?length=1&background=random&name=${finit}`: `https://avatars.dicebear.com/v2/initials/A.svg`}
      />
       <Typography
        className={classes.name}
        variant="caption"
      >
        {meta ? meta.email: ''}
      </Typography>
      <Typography
        variant="h6"
      >
        {meta ? meta.name: ''}
      </Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;