import React, { Fragment, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { crud } from '../services/crud';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { AuthContext } from '../auth/AuthContext';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import http from '../services/http';
import config from '../config/config';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

const useStyles = makeStyles(theme => (
  {
    table: {
      minWidth: 700,
    },
    container: {
      padding: '100px 1em 1em 1em',
      minHeight: '100vh',
      width: '100%',
      zIndex: '1200'
    },
    button: {
      margin: '0.5em 0',
      background: '#e0e0e0',
      padding: "0.5em 1.5em"
    },
    close: {
      padding: theme.spacing(0.5),
    },
    formControl: {
      minWidth: '220px',
      margin: '1em 0'
    },
    selectMenu: {
      '& .MuiSelect-outlined': {
        display: 'flex',
        alignItems: 'center'
      },
    }
  }
));

const AdminSetting = ({ history, match }) => {
  const { user } = React.useContext(AuthContext);
  const [order, setOrder] = React.useState({ _id: 1, data: {} });
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [command, setCommand] = React.useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const classes = useStyles();
  const orders = crud('settings');

  const orderId = match.params.id;

  const getOrder = async () => {
    let res = await orders.findOne(orderId);
    if (res) {
      if (res.data) {
        setOrder({ ...res.data });
        // initEditor(res.data.data);
      }
    }
  }

  const addItem = () => {
    const d = order.data;
    if(order.type == 2) {
      d.push(new Date);
    } else {
      d.push("");
    }
    setOrder({ ...order, data: [...d] });
  }

  const deleteItem = (idx) => {
    const d = order.data;
    d.splice(idx, 1);
    setOrder({ ...order, data: [...d] });
  }

  const onChange = (idx, value) => {
    const d = order.data;
    d[idx] = value;
    setOrder({ ...order, data: [...d] });
  }

  const update = (command) => {
    setCommand(command);
    setDialogOpen(true);
  }

  const updateOrder = async () => {
    handleDialogClose();
    let _order = {
      ...order,
      updatedBy: user.name
    }
    let res = await orders.save(_order);
    if (res) {
      if (res.data) {
        setMsg("Settings Updated");
        setOpen(true);
        setOrder({ _id: 1, data: {} });
        getOrder();
      }
    }
  }

  const backupOrder = async () => {
    handleDialogClose();
    const res = await http({
      method: "POST",
      url: config.app.server.url + "/settings/backup/" + orderId,
    });
    if (res) {
      if (res.data) {
        setMsg("Settings Backed Up");
        setOpen(true);
      }
    }
  }

  const restoreOrder = async () => {
    handleDialogClose();
    const res = await http({
      method: "POST",
      url: config.app.server.url + "/settings/restore/" + orderId,
    });

    if (res) {
      if (res.data) {

        setOrder({ _id: 1, data: {} });
        getOrder();

        setMsg("Settings Restored");
        setOpen(true);
      }
    }
  }

  const executeCommand = () => {
    switch (command) {
      case 'save': updateOrder(); break;
      case 'backup': backupOrder(); break;
      case 'restore': restoreOrder(); break;
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleDialogClose = (command) => {
    setDialogOpen(false);
  };

  const handleDateChange = (idx, value) => {
    console.log(value);
    onChange(idx, value);
  }

  const handleChange = (e) => {
    setOrder({ ...order, data: { ...e } });
  }

  React.useEffect(() => {
    getOrder();
  }, []);

  return (
    <div className={classes.container}>
      <Container maxWidth="md" component={Paper} elevation={3} style={{ padding: '1.5em' }}>
        <Button
          onClick={() => { history.push("/settings") }}
          startIcon={<ArrowBackIcon />}
        >
          Back to Settings
        </Button>
        <Typography variant='h3' style={{ margin: '15px 0' }}>{order.key}</Typography>
        {order &&
          <Fragment>
            <div style={{ background: '#f8f8f8', padding: '15px', width: '100%' }}>
              {
                Array.isArray(order.data) ?
                  <Fragment>
                    {
                      order.data.map((item, x) => (
                        <Container style={{ padding: '10px 0' }}>
                          {
                            order.type == 2 ? 
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        inputVariant="filled"
                                        label="Date"
                                        format="MMM dd, yyyy"
                                        value={item}
                                        InputAdornmentProps={{ endAdornment: "start" }}
                                        onChange={(value) => {handleDateChange(x, value)}}
                                        margin="dense"
                                        // error={isNotValidTime()}
                                    />
                                </MuiPickersUtilsProvider>
                            :
                            <TextField variant='outlined' value={item}
                            onChange={e => { onChange(x, e.target.value) }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start"><b>{x}</b></InputAdornment>,
                            }} />
                          }
                          <IconButton
                            key="close"
                            aria-label="close"
                            color="inherit"
                            onClick={() => { deleteItem(x) }}
                            style={{ padding: '15px', margin: '0 5px' }}
                          >
                            <DeleteIcon color='error' />
                          </IconButton>
                        </Container>
                      ))
                    }
                    <Button color="primary" variant="outlined" onClick={addItem}>Add</Button>
                  </Fragment>
                  :
                  <Editor
                    value={order.data}
                    key={order._id}
                    onChange={handleChange}
                  />
              }
            </div>
            <br />
            <br />
            <Button color="primary" variant="contained" onClick={() => { update('save'); }}>Save</Button>
            <Button style={{ marginLeft: '10px' }} variant="contained" onClick={() => { update('backup'); }}>Backup</Button>
            <Button style={{ marginLeft: '10px' }} variant="contained" onClick={() => { update('restore'); }}>Restore</Button>
          </Fragment>
        }
      </Container>

      {/* <pre>{JSON.stringify(order, null, 4)}</pre> */}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{msg}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to continue?
          </DialogContentText>
          <p>Command: {command.toUpperCase()}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No
          </Button>
          <Button onClick={executeCommand} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
};

export default withRouter(AdminSetting);