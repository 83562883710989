import React, { Component } from 'react';
import $http from './http';
import config from '../config/config';

class CrudModel {
  constructor(model) {
    this.model = model;
    this.signal = $http.CancelToken.source();
  }

  findOne(id) {
    return $http({
      method: 'get',
      url: config.app.server.url + `/${this.model}/${id}`,
      cancelToken: this.signal.token
    })
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        console.log(err);
      });
  }

  save(doc) {
    let id = doc.id || doc._id;
    let method = id ? 'put' : 'post';
    let url =
      config.app.server.url + `/${this.model}` + (id ? '/' + id : '');
    return $http({
      method: method,
      url: url,
      data: doc,
      cancelToken: this.signal.token
    })
      .then(res => {
        if (!res.data.id) {
          return Promise.reject(res);
        }
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  erase(id) {
    return $http({
      method: 'delete',
      url: config.app.server.url + `/${this.model}/${id}`,
      cancelToken: this.signal.token
    })
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        console.log(err);
      });
  }

  find(params = {}) {
    const query = params;
    query._limit = query._limit || 50;
    query._start = query._start || 0;

    if (query._page) {
      query._start = query._page * query._limit;
      delete query._page;
    }

    return $http
      .get(config.app.server.url + `/${this.model}`, {
        params: query,
        cancelToken: this.signal.token
      })
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        console.log(err);
      });
  }

  cancel(reason) {
    this.signal.cancel(reason);
  }
}

export function crud(model) {
  return new CrudModel(model);
}
