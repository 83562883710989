import React, {Fragment, useState} from 'react';
import {withRouter} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { StateHelper } from 'react-form-plus';
import http from '../services/http';
import config from '../config/config';
import {AuthContext} from './AuthContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import grey from '@material-ui/core/colors/grey';

const fs = new StateHelper();

const useStyles = makeStyles(theme => (
    {
    input: {
        backgroundColor: theme.palette.common.white,
        margin: '8px 0'
    },
    formControl: {
        backgroundColor: theme.palette.common.white,
        margin: '1em 0'
      },
     loginButton: {
        color: '#fff',
        marginLeft: 'auto',
        display: 'block',
        marginTop: '10px'
      },
      button: {
        margin: '0.5em 0',
        '&:disabled': {
            background: grey[500],
         }
      },
      divider: {
          margin: '2em 0',
          backgroundColor: theme.palette.common.white
      },
      icon: {
          maxHeight: '1.5em',
          margin: '0 1em'
      },
      loader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px'
      },
      error: {
        backgroundColor: theme.palette.error.dark,
      },
      close: {
        padding: theme.spacing(0.5),
      },
      snackBar: {
        backgroundColor: theme.palette.error.dark
      },
      iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
      },
      message: {
        display: 'flex',
        alignItems: 'center',
      }
  }));

const LoginForm = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({email: '', password: ''});
    fs.useState(state, setState);
    const auth = React.useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = React.useState('');

    let error = {open: false, value: ''};

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const login = async () => {
      setIsLoading(true);

      const res = await http({
        method: "POST",
        url: config.app.server.url + "/auth/local",
        data: {
          identifier: state.email,
          password: state.password
        }
      }).then(res => {
          http.defaults.headers.common["Authorization"] = "Bearer " + res.data.jwt;
          const me = res.data.user;
          me.token = res.data.jwt;
          auth.setUser(me);
          props.history.push('/orders/all');
      }).catch(err => {
        if(err.response) {
          if(err.response.status == 400) {
            showError("Email/Password is invalid.");
            return;
          }
        }
        showError("Something went wrong.");
      });

      setIsLoading(false);
    }
      const showError = (err) => {
        setMsg(err);
        setOpen(true);
       }
       const closeError = () => {
        error = {open: true, value: ''};
       }

    // React.useEffect(() => {
    //     checkRedirect();
    // }, []);
    return (
        <Fragment>
         {isLoading ?
            <div className={classes.loader}><CircularProgress /></div>
            :
            <Fragment>
              <TextField type="email" id="outlined-basic" className={classes.input} label="Email Address" {...fs.model('email')} variant="filled" fullWidth margin="dense"/>
              <TextField type="password" id="outlined-basic" className={classes.input} label="Password" {...fs.model('password')} variant="filled" fullWidth margin="dense"/>
              <Button color="primary" variant="contained" className={classes.loginButton} onClick={login}>
                  Login
              </Button>
              { error.open && <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={error.open}
                autoHideDuration={6000}
                ContentProps={{
                  'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{error.value}</span>}
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={closeError}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
      />

              }
            </Fragment>
         }

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
         <SnackbarContent
            className={classes.snackBar}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <ErrorIcon className={classes.iconVariant}/>
                {msg}
              </span>
            }
            action={[
              <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon/>
              </IconButton>,
            ]}
          />
        </Snackbar>
            
        </Fragment>
    )
}

export default withRouter(LoginForm);