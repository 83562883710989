import React, {Suspense} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import leslielogo from '../assets/leslie_app.png';
import LoginForm from '../auth/LoginFormAdmin';

const useStyles = makeStyles(theme => (
    {
    container: {
        height: '100vh', 
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    card: {
        // backgroundColor: '#02132f',
        padding: '1em'
    },
    header: {
        textAlign: 'center',
        margin: '0.5em'
    },
    img: {
        width: "80%",
        display: 'block',
        margin: '2em auto'
    }
  }));


const AdminLogin = () => {
    const classes = useStyles();

    return(
        <div className={classes.container}>         
            <Container maxWidth="xs">
            <Card className={classes.card} elevation={5}>
                <CardContent>
                    <Typography className={classes.header} variant="h5">
                        Dashboard Login
                    </Typography>
                    <img className={classes.img} src={leslielogo} />
                    <LoginForm />
                </CardContent>
             </Card>
            </Container>
        </div>
    )
}

export default AdminLogin;