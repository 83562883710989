import React from "react";
import {
  HashRouter as Router,
} from "react-router-dom";
import Routes from "./routes";
import {AuthProvider} from "./auth/AuthContext";
import {OrderProvider} from "./store/OrderContext";
import theme from './theme';
import { ThemeProvider } from '@material-ui/styles';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
          <OrderProvider>
            <Router>
              <Routes />
            </Router>
          </OrderProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;